/* eslint-disable jsx-a11y/iframe-has-title */
//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
// import Button from '@mui/material/Button';
import Button from '../components/BaseComponents/Button'
import { createUseStyles } from 'react-jss';
import {
  prepareFormData,
  getValue,
  formatDistanceTime,
  formatDate,
  getPrice,
} from '../contexts/Utils';
import Api from '../contexts/Api';
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import { useParams } from 'react-router-dom';
import GeoSearch from '../components/GeoSearch';
import styled from 'styled-components';
import TitleSeparator from '../components/BaseComponents/TitleSeparator';
import Dropzone from '../components/BaseComponents/Dropzone';
import MissionFiles from '../components/MissionDetail/MissionFiles';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const CreateMission = (props) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [dateType, setDateType] = useState(1);
  const [recoveryDateType, setRecoveryDateType] = useState(1);
  const [vehicleType, setVehicleType] = useState(1);
  const [companySearch, setCompanySearch] = useState({ origin: [], destination: [] });
  const [companyData, setCompanyData] = useState([]);
  const [localisation, setLocalisation] = useState({});
  const [mission, setMission] = useState({});
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [electricChecked, setElectricChecked] = useState(0);
  const [electricRecoveryChecked, setElectricRecoveryChecked] = useState(0);
  const [recovery, setRecovery] = useState();
  const { notification } = useNotification();
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState();
  const [newAddress, setNewAddress] = useState(false);
  const [geoData, setGeoData] = useState({});
  const [parentFiles, setParentFiles] = useState([]);
  const [contactPhone, setContactPhone] = useState({ value: '', recovery: false })
  const [contactEmail, setContactEmail] = useState({ value: '', recovery: false })
  const [contactName, setContactName] = useState({ value: '', recovery: false })
  const [contactCompany, setContactCompany] = useState({ value: '', recovery: false })
  const [options, setOptions] = useState({})

  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;
  const { uuid } = useParams();
  let edit = props.edit;
  const navigate = useNavigate();

  //
  // ─── GET MISSION DETAIL ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchMissions = async () => {
      const getMissions = await Api({
        endpoint: `/missions?user_id=${user_id}&uuid=${uuid}&detail=true${`&company_id=${props.role === 'super-admin' ? 'all' : user_id
          }`}${edit ? '&edit=true' : ''}`,
        method: 'GET',
      });
      if (getMissions.success && getMissions.data && Object.keys(getMissions.data).length) {
        setMission(getMissions.data);
        setDateType(getValue(getMissions.data, ['date_type']));
        setRecoveryDateType(getValue(getMissions.data, ['recovery_mission', 'date_type']));
        setInsuranceInput({
          checked: getValue(getMissions.data, ['insurance']) || 0,
          disabled: getValue(getMissions.data, ['insurance']) && getValue(getMissions.data, ['special_plate']) ? true : false
        })
        setRecoveryInsuranceInput({
          checked: getValue(getMissions.data, ['recovery_mission', 'insurance']) || 0,
          disabled: getValue(getMissions.data, ['recovery_mission', 'insurance']) && getValue(getMissions.data, ['recovery_mission', 'special_plate']) ? true : false
        })

        if (getValue(getMissions, ['data', 'options'])) {
          setOptions(getValue(getMissions, ['data', 'options']));
        }
      }
    };

    const fetchOptions = async () => {
      const getClientOptions = await Api({ endpoint: `/users/options?garage_id=${user_id}`, method: 'GET' })
      setOptions(getClientOptions.data)
    }
    if (!Object.keys(mission).length && edit) {
      fetchMissions();
    } else if (!edit && Object.keys(mission).length) {

      setMission({});
      setFormData({});
    }

    if (props.role == 'garage') {
      fetchOptions();
    }
  }, [mission, uuid, user_id, edit, props.role]);

  //
  // ─── HANDLE DATE TYPE ───────────────────────────────────────
  //
  const handleDateType = (e) => {
    setDateType(e);
  };

  //
  // ─── HANDLE DATE TYPE ───────────────────────────────────────
  //
  const handleVehicleType = (e) => {
    setVehicleType(e);
  };

  //
  // ─── HANDLE GEO SEARCH FOR TRAVEL ───────────────────────────────────────
  //
  const handleGeoSearch = async () => {
    let data = prepareFormData({ formId: 'geoSearch' });
    let addresses = prepareFormData({ formId: 'addresses-form' });

    if (!data.fields.car_registration && !data.fields.car_grey_card) {
      if (!('errors' in data)) data['errors'] = {}
      data['errors']['car_grey_card'] = {
        error: true,
        message: "Le numéro de châssis est obligatoire si l'immatriculation n'est pas renseignée",
      };
    }

    if (data.errors) {
      setErrors({ ...data.errors });
    } else {
      setErrors({});
      let fields = data.fields;

      if (addresses.fields.hasOwnProperty('destination_additional_address')) fields.destination_additional_address = addresses.fields.destination_additional_address;
      if (addresses.fields.hasOwnProperty('origin_additional_address')) fields.origin_additional_address = addresses.fields.origin_additional_address;

      delete addresses.fields.destination_additional_address;
      delete addresses.fields.origin_additional_address;

      let geoApiData = {
        origin: [],
        destination: [],
      };

      //
      // ─── PREPARE ORIGIN AND DESTINATION DATA ───────────────────────────────────────
      //
      let localisationFields = ['address', 'cp', 'city'];
      let localisationType = ['origin', 'destination'];

      localisationType.map((type) => {
        localisationFields.map((field) => {
          let value = addresses.fields[`${type}_${field}`];
          if (addresses.fields[`${type}_${field}`]) {
            geoApiData[type].push(value);
          }
        });
      });

      //
      // ─── GOOGLE DISTANCE API CALL ───────────────────────────────────────
      //
      const params = {
        endpoint: '/map',
        data: geoApiData,
      };
      const getTravelInfos = await Api(params);

      // let distanceData = DistanceDataDefault
      let distanceData = getTravelInfos.data;
      if (distanceData) {

        // Handle custom options
        const options_custom = []
        const recovery_options_custom = []
        Object.entries(data.fields).map(([key, value]) => {
          if (key.startsWith('option_custom_') && value === true) {
            options_custom.push(key.split('option_custom_')[1])
          }
          if (key.startsWith('recovery_option_custom_') && value === true) {
            recovery_options_custom.push(key.split('recovery_option_custom_')[1])
          }
        })

        const formatedGeo = {
          date: fields.date,
          vehicle_availability: fields.vehicle_availability,
          vehicle_type: fields.vehicle_type,
          insurance: fields.insurance,
          special_plate: fields.special_plate,
          easy_to_use: fields.easy_to_use,
          cleaning: fields.cleaning,
          recovery_insurance: fields.recovery_insurance,
          recovery_special_plate: fields.recovery_special_plate,
          recovery_easy_to_use: fields.recovery_easy_to_use,
          recovery_cleaning: fields.recovery_cleaning,
          destination_addresses: distanceData.destination_addresses,
          origin_addresses: distanceData.origin_addresses,
          destination_additional_address: fields.destination_additional_address,
          origin_additional_address: fields.origin_additional_address,
          distance:
            (getValue(distanceData, ['rows', 0, 'elements', 0, 'distance', 'value']) || 0) / 1000,
          duration: getValue(distanceData, ['rows', 0, 'elements', 0, 'duration', 'value']),
          options_custom: options_custom.join(','),
          recovery_options_custom: recovery_options_custom.join(',')
        };
        setFormData(formatedGeo);
      }
    }
  };

  //
  // ─── HANDLE DRIVER SEARCH ───────────────────────────────────────
  //
  const handleUserSearch = async (opt) => {
    let value = opt.value;

    const params = {
      endpoint: `/users?user_id=${user_id}&search=${value}&fields=name,firstname,user_id,email&user_type=1&columns=name,firstname`,
      method: 'GET',
    };

    const getUsers = await Api(params);
    if (getUsers.success) {
      const formatData = getUsers.data.map((value) => {
        return { label: `${value.name} ${value.firstname} (${value.email})`, id: value.user_id };
      });
      setUsers(formatData);
    }
  };

  //
  // ─── HANDLE COMPANY SEARCH ───────────────────────────────────────
  //
  const handleCompanySearch = async (opt) => {
    let value = opt.value;
    let name = opt.name;

    if (value) {
      const params = {
        endpoint: `/users?user_id=${user_id}&search=${value}&fields=company,address,cp,city,user_id,partner,partner_custom&user_type=2&columns=company,address,cp,city&${opt.attribute_company ? 'attribute_company=true' : ''}`,
        method: 'GET',
      };

      const getCompany = await Api(params);
      if (getCompany.success) {
        const formatData = getCompany.data.map((el) => {
          if (name === 'company_id') {
            return { label: `${el.company}`, id: el.user_id, partner: el.partner, partner_custom: el.partner_custom };
          } else {
            return {
              label: `${el.company} (${el.address} ${el.cp} ${el.city})`,
              id: el.user_id,
              address: el.address,
              cp: el.cp,
              city: el.city,
            };
          }
        });

        if (name === 'company_id') {
          setCompanyData(formatData);
        } else {
          setCompanySearch({
            ...companySearch,
            [name]: formatData,
          });
        }
      }
    } else if (name !== 'company_id') {
      setLocalisation({});
    }
  };

  const handleCompanyData = async (opt) => {
    let name = opt.name;
    let value = opt.value;

    if (value) {
      const getClientOptions = await Api({ endpoint: `/users/options?garage_id=${value.id}`, method: 'GET' })
      setOptions(getClientOptions.data)
    } else {
      setOptions({})
    }

    if (name !== 'company_id') {
      setLocalisation({
        ...localisation,
        [name]: {
          address: value.address,
          cp: value.cp,
          city: value.city,
          id: value.id,
        },
      });
    }
  };

  //
  // ─── CREATE MISSION ───────────────────────────────────────
  //
  const handleChange = async () => {
    // setSummaryOpen(true);
    let data = prepareFormData({ formId: 'geoSearch' });
    let addresses = prepareFormData({ formId: 'addresses-form' });
    if (data.errors) setErrors(data.errors);

    if (!data.errors) {
      delete data.fields.origin;
      delete data.fields.destination;
      delete data.fields.recovery_destination;

      if (!data.fields.driver_id) {
        delete data.fields.driver_id;
      }

      if (edit && !props.duplicate) {
        data.uuid = uuid;
        data.company_id = mission.company_id;
      }

      // insurance must be enabled to choose special plate
      if (!data.fields.insurance) data.fields.special_plate = false;

      //
      // ─── PREPARE ORIGIN AND DESTINATION DATA ───────────────────────────────────────
      //
      let localisationFields = ['address', 'cp', 'city', 'additional_address'];
      let localisationType = ['origin', 'destination'];

      localisationType.map((type) => {
        localisationFields.map((field) => {
          let value = addresses.fields[`${type}_${field}`];
          if (addresses.fields[`${type}_${field}`]) {
            data.fields[`${type}_${field}`] = value;
          }
        });
      });

      // Set status to 3 (super-admin validation)
      if (!edit && props.role !== 'super-admin') {
        data.fields.status = 3
      } else if ((!edit || props.duplicate) && props.role === 'super-admin') {
        data.fields.status = 1
      }

      // Handle custom options
      let options_custom = []
      let recovery_options_custom = []

      Object.entries(data.fields).map(([key, value]) => {
        if (key.startsWith('option_custom_') && value === true) {
          options_custom.push(key.split('option_custom_')[1])
        }
        if (key.startsWith('recovery_option_custom_') && value === true) {
          recovery_options_custom.push(key.split('recovery_option_custom_')[1])
        }
        if (key.startsWith('option_custom_') || key.startsWith('recovery_option_custom_')) {
          delete data.fields[key];
        }
      })
      data.fields.options_custom = options_custom.join(',')
      data.fields.recovery_options_custom = recovery_options_custom.join(',')

      if (validationModalOpen) {
        const formData = prepareFormData({ formId: 'validation_form' });
        data.fields = { ...data.fields, ...formData.fields, ...{ referent: user_id } }
      }

      if ((mission.hasOwnProperty('recovery') && mission.recovery && recovery === undefined) || recovery) {
        data.recovery = true;
      }

      if (recovery || (props.duplicate && mission.recovery && recovery !== false)) { data.fields.recovery = 1 }

      let createMission = {};
      if (edit && !props.duplicate) {
        //
        // ─── HANDLE MISSION UPDATE ───────────────────────────────────────
        //
        const params = { endpoint: `/missions`, method: 'PUT', data };
        createMission = await Api(params);
        notification({ variant: createMission.success ? 'success' : 'error', message: createMission.message });
      } else {
        //
        // ─── HANDLE FILES ON MISSION CREATE ───────────────────────────────────────
        //
        const formData = new FormData();
        parentFiles.forEach((file) => formData.append(`files[]`, file));

        Object.entries(data).map(([key, value]) => formData.append(key, JSON.stringify(value)))
        const params = { endpoint: `/missions`, method: 'POST', data: formData, removeHeader: true };
        createMission = await Api(params);
        notification({ variant: createMission.success ? 'success' : 'error', message: createMission.message });
      }

      createMission.success && navigate('/');
    }
  };

  // 
  // ─── GET DROPZONE FILES ───────────────────────────────────────
  //
  const handleFilesChange = (files) => { setParentFiles(files) };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
    },
    container: {
      boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
      background: '#FFF',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    inputDuo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '& .formfield_container': {
        width: 'calc(50% - 10px) !important',
      }
    },
    outerContainer: {
      display: 'flex',
      gap: 20,
      '&>div': {
        width: '50%',
      },
      '@media screen and (max-width:780px)': {
        flexDirection: 'column',
        '&>div': {
          width: 'auto !important',
        },
      },
    },
    contactInfosContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
      '@media screen and (max-width:780px)': {
        flexDirection: 'column',
        '&>div': {
          width: '100% !important',
        },
      },
    },
    form: {
      // maxWidth: 1200,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    formDate: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 20,
    },
    carInfos: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      '& .formfield_container': {
        width: 'fit-content !important'
      }
    },
    map: {
      width: '50%',
      maxWidth: 500,
    },
    travelContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
    },
    travelTitle: {
      marginBottom: 20,
      '& h2': {
        margin: 0,
      },
    },
    travelInfos: {
      display: 'flex',
      alignItems: 'center',
    },
    travelIcon: {
      marginRight: 10,
    },
    travelDetailContainer: {
      display: 'flex',
      width: '100%',
    },
    travelDetail: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20,
    },
    travelDetailAddress: {
      display: 'flex',
      flexDirection: 'column',
      '&:nth-child(1)': {
        marginBottom: 30,
      },
      '& span:nth-child(2)': {
        color: '#9b9b9b',
      },
    },
    travelSteps: {
      display: 'flex',
      margin: 'auto 0',
      minWidth: 500,
    },
    travelMap: {
      width: '100%',
    },
    travelLine: {
      display: 'flex',
      flexDirection: 'column',
      '& span:nth-child(1), span:nth-child(3)': {
        display: 'block',
        width: 10,
        height: 10,
        background: 'var(--primary-color)',
        borderRadius: '100%',
        border: '1px solid var(--primary-color)',
      },
      '& span:nth-child(2)': {
        width: 2,
        background: '#e8e9f5',
        height: 'calc(100% - 30px)',
        display: 'block',
        margin: '5px auto',
      },
      '& span:nth-child(3)': {
        background: 'none',
      },
    },
    formSubmit: {
      marginLeft: 'auto !important',
    },
    summaryModal: {
      position: 'absolute',
      background: '#fff',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '25px',
    },
    submitButton: {
      display: 'flex',
      '&>div': {
        width: 'initial !important',
        marginLeft: 'auto',
      },
      '&>button': {
        marginLeft: '0 !important'
      }
    },
    validationModal: {
      position: 'absolute',
      background: '#fff',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '25px',
      width: '350px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& h2': {
        marginBottom: "35px",
        alignSelf: "center"
      }
    },
    modalInput: {
      marginTop: 20,
      display: 'flex',
      marginBottom: '10px',
      gap: 10
    },
    cardDates: {
      marginTop: 5,
      display: 'flex',
      gap: 20,
      fontSize: '0.9em'
    },
    highlighted: {
      color: 'var(--primary-color)',
      fontWeight: 'bold'
    },
    cancelMissionContainer: {
      fontSize: 12
    },
    cancelMissionContent: {
      display: 'flex',
      flexDirection: 'column',
    }
  });
  const classes = useStyle();

  //
  // ─── CHECK IF DATA ARE DEFINED ───────────────────────────────────────
  //
  const checkGeo = formData.origin_addresses && formData.destination_addresses;

  //
  // ─── OPTIONS ON CHANGE ───────────────────────────────────────
  //
  const [insuranceInput, setInsuranceInput] = useState({
    checked: mission.insurance || 0,
    disabled: false
  });

  const [recoveryInsuranceInput, setRecoveryInsuranceInput] = useState({
    checked: getValue(mission, ['recovery_mission', 'insurance']) || 0,
    disabled: false
  });

  const disableItineraire = (value, name) => {
    // remove itineraire block when options change
    setFormData({});

    // enable insurance when special_plate is checked 
    if (name === 'special_plate' && value === 1) {
      setInsuranceInput({ checked: 1, disabled: true });
    }
    else if (name === 'special_plate') {
      setInsuranceInput({ checked: insuranceInput.checked, disabled: false });
    }
    else if (name === 'insurance') {
      setInsuranceInput({ checked: value, disabled: false });
    }

    if (name === 'recovery_special_plate' && value === 1) {
      setRecoveryInsuranceInput({ checked: 1, disabled: true });
    }
    else if (name === 'recovery_special_plate') {
      setRecoveryInsuranceInput({ checked: recoveryInsuranceInput.checked, disabled: false });
    }
    else if (name === 'recovery_insurance') {
      setRecoveryInsuranceInput({ checked: value, disabled: false });
    }
  }

  const handleModal = () => {
    let data = prepareFormData({ formId: 'geoSearch' });

    if (edit) {
      const destination = getValue(geoData, ['destination']) || {}
      const origin = getValue(geoData, ['origin']) || {}

      if (Object.entries(geoData).length && (destination.destination_address !== mission.destination_address || origin.origin_address !== mission.origin_address || destination.destination_cp !== mission.destination_cp || origin.origin_cp !== mission.origin_cp)) {
        setNewAddress(true)
      }
    }

    if (data.errors) setErrors(data.errors);
    else setValidationModalOpen(true)
  }

  const handleChangeGeo = (values) => {
    setFormData({})
    setGeoData({ ...geoData, ...values })
  }

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  let priceFinal;

  if (checkGeo && props.role === 'super-admin') {
    priceFinal = getPrice({ vehicle_type: vehicleType, formData, partner: getValue(companyData[0], ['partner']) || props.partner, partner_custom: getValue(companyData[0], ['partner_custom']), recovery: recovery, removeSuffix: true, options })
  }

  let vehicleIcon = <svg xmlns="http://www.w3.org/2000/svg" className={classes.travelIcon} width='24' height='24' viewBox="0 0 640 512"><path d="M640 320V368C640 385.7 625.7 400 608 400H574.7C567.1 445.4 527.6 480 480 480C432.4 480 392.9 445.4 385.3 400H254.7C247.1 445.4 207.6 480 160 480C112.4 480 72.94 445.4 65.33 400H32C14.33 400 0 385.7 0 368V256C0 224.5 22.78 198.3 52.78 192.1L97.05 82.29C109.2 51.92 138.6 32 171.3 32H353.2C377.5 32 400.5 43.05 415.7 62.02L519.9 192.2C586.9 196.3 640 251.1 640 320H640zM171.3 64C151.7 64 134.1 75.95 126.8 94.17L87.63 192H224V64H171.3zM256 192H478.7L390.7 82.01C381.6 70.63 367.8 64 353.2 64H256L256 192zM541.1 400C543.3 394.9 544 389.5 544 384C544 378.5 543.3 373.1 541.1 368C534.9 340.4 509.8 320 480 320C450.2 320 425.1 340.4 418 368C416.7 373.1 416 378.5 416 384C416 389.5 416.7 394.9 418 400C425.1 427.6 450.2 448 480 448C509.8 448 534.9 427.6 541.1 400zM385.3 368C392.9 322.6 432.4 288 480 288C527.6 288 567.1 322.6 574.7 368H608V320C608 266.1 565 224 512 224H64C46.33 224 32 238.3 32 256V368H65.33C72.94 322.6 112.4 288 160 288C207.6 288 247.1 322.6 254.7 368H385.3zM221.1 400C223.3 394.9 224 389.5 224 384C224 378.5 223.3 373.1 221.1 368C214.9 340.4 189.8 320 160 320C130.2 320 105.1 340.4 98.02 368C96.7 373.1 96 378.5 96 384C96 389.5 96.7 394.9 98.02 400C105.1 427.6 130.2 448 160 448C189.8 448 214.9 427.6 221.1 400z" /></svg>

  if (formData.vehicle_type === '2') {
    vehicleIcon = <svg xmlns="http://www.w3.org/2000/svg" className={classes.travelIcon} width='24' height='24' viewBox="0 0 640 512"><path d="M64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H481.3C495.3 96 508.6 102.1 517.7 112.8L596.4 204.6C603.9 213.3 608 224.4 608 235.8V384H624C632.8 384 640 391.2 640 400C640 408.8 632.8 416 624 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H255.1C255.1 469 213 512 159.1 512C106.1 512 63.1 469 63.1 416H64zM32 64V352C32 369.7 46.33 384 64 384H69.46C82.64 346.7 118.2 320 160 320C201.8 320 237.4 346.7 250.5 384H384V64C384 46.33 369.7 32 352 32H64C46.33 32 32 46.33 32 64zM570.9 224L493.4 133.6C490.4 130 485.1 128 481.3 128H416V224H570.9zM416 256V344.4C432.1 329.2 455.4 320 480 320C521.8 320 557.4 346.7 570.5 384H576V256H416zM160 352C124.7 352 96 380.7 96 416C96 451.3 124.7 480 160 480C195.3 480 224 451.3 224 416C224 380.7 195.3 352 160 352zM480 480C515.3 480 544 451.3 544 416C544 380.7 515.3 352 480 352C444.7 352 416 380.7 416 416C416 451.3 444.7 480 480 480z" /></svg>
  } else if (formData.vehicle_type === '3') {
    vehicleIcon = <svg xmlns="http://www.w3.org/2000/svg" className={classes.travelIcon} width='24' height='24' viewBox="0 0 640 512"><path d="M640 250.7V360C640 379.4 626.3 395.5 607.1 399.2L608 400C608 444.2 572.2 480 528 480C483.8 480 448 444.2 448 400H288C288 444.2 252.2 480 208 480C181.8 480 158.6 467.4 144 448C129.4 467.4 106.2 480 80 480C35.82 480 0 444.2 0 400V104C0 64.24 32.24 32 72 32H408C447.8 32 480 64.24 480 104V128H536C551.1 128 565.3 135.1 574.4 147.2L630.4 221.9C636.6 230.2 640 240.3 640 250.7zM32 104V335.1C45.37 325.1 61.99 320 80 320C106.2 320 129.4 332.6 144 351.1C158.6 332.6 181.8 320 208 320C240.8 320 268.1 339.7 281.3 368H448V104C448 81.91 430.1 64 408 64H72C49.91 64 32 81.91 32 104zM528 320C560.8 320 588.9 339.7 601.3 367.9C605.1 367.3 608 363.1 608 360V256H480V335.1C493.4 325.1 509.1 320 528 320zM480 160V224H592L548.8 166.4C545.8 162.4 541 160 536 160H480zM528 448C554.5 448 576 426.5 576 400C576 373.5 554.5 352 528 352C501.5 352 480 373.5 480 400C480 426.5 501.5 448 528 448zM208 352C181.5 352 160 373.5 160 400C160 426.5 181.5 448 208 448C234.5 448 256 426.5 256 400C256 373.5 234.5 352 208 352zM80 448C106.5 448 128 426.5 128 400C128 373.5 106.5 352 80 352C53.49 352 32 373.5 32 400C32 426.5 53.49 448 80 448z" /></svg>
  }

  const isRecovery = ((mission.hasOwnProperty('recovery') && mission.recovery)) || recovery

  // Default value for recovery destination address
  let recoveryDestination = {}
  if ((edit || props.duplicate) && getValue(mission, ['origin_city'])) {
    recoveryDestination = { address: mission.origin_address, city: mission.origin_city, cp: mission.origin_cp, geo: `${mission.origin_address} ${mission.origin_cp} ${mission.origin_city}` }
  } else if (geoData.origin) {
    const origin = geoData.origin;
    recoveryDestination = { address: origin.address, city: origin.city, cp: origin.cp, geo: `${origin.address} ${origin.cp} ${origin.city}` }
  }

  const isDisabledEdit = !props.duplicate && mission.status >= 4 ? true : false;

  if (props.duplicate && mission) {
    delete mission.driver_id
  }

  return (
    ((edit && Object.keys(mission).length) || !edit) && (
      <>
        <Modal
          open={summaryOpen}
          onClose={() => setSummaryOpen(false)}
        >
          <Box className={classes.summaryModal}>
            <div className={classes.travelTitle}>
              <h2>Récapitulatif</h2>
              <div className={classes.cardDates}>
                <span>Date :<span className={classes.highlighted}>{` ${dateType !== 2 ? window._DATA.date_type[dateType].label : ''} ${formatDate({ time: formData.date, display: 'date' })}`}</span></span>
                <span>Disponibilité du véhicule : <span className={classes.highlighted}>{`${formatDate({ time: formData.vehicle_availability, display: 'date' })}`}</span></span>
              </div>
              <p>Le prix indiqué n'est pas définitif et peut être modifié par le super-admin lors de la validation de la mission.</p>
              {/* <p>Vous avez la possibilité d'envoyer les fichiers nécessaires à la mission à l'adresse suivante : convoyage@saint-bernard.com</p> */}
            </div>
            <div className={classes.travelContainer} style={{ marginBottom: '40px' }}>
              <div className={classes.travelInfos}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="" className={classes.travelIcon}>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M13 12H18"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M12 5V11"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"></path>
                </svg>
                <span>{formatDistanceTime({ duration: formData.duration })}</span>
                <Formfields field={{ type: 'hidden', component: 'text', name: 'duration', hidden: true, value: formData.duration }} />
              </div>
              <div className={classes.travelInfos}>
                {vehicleIcon}
                <span>{formatDistanceTime({ distance: formData.distance })}</span>
                <Formfields field={{ type: 'hidden', component: 'text', name: 'distance', hidden: true, value: formData.distance }} />
              </div>
              <div className={classes.travelInfos}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="" className={classes.travelIcon}>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M22 5H2V19H22V5Z"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M18 5C18 7.21 19.79 9 22 9V5H18Z"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M18 19C18 16.79 19.79 15 22 15V19H18Z"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M6 5C6 7.21 4.21 9 2 9V5H6Z"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M6 19C6 16.79 4.21 15 2 15V19H6Z"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M12 16V14.99"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M12 9.01V8"></path>
                  <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M14 10.2701C13.83 9.65006 13.28 9.17006 12.58 9.04006C11.88 8.91006 11.15 9.15006 10.74 9.64006C10.69 9.70006 10.23 10.2601 10.45 10.8601C10.59 11.2401 10.92 11.4501 11.38 11.7301C11.88 12.0401 12.02 11.9901 12.78 12.3801C13.55 12.7801 13.68 12.9801 13.74 13.0801C13.96 13.5001 13.81 13.9201 13.78 14.0001C13.57 14.5401 12.99 14.7601 12.78 14.8501C12.69 14.8901 11.85 15.1901 11.02 14.8101C10.56 14.6001 10.32 14.2801 10.24 14.1801C10.11 13.9901 10.04 13.8201 10 13.6901"></path>
                </svg>
                <span>{getPrice({ vehicle_type: vehicleType, formData, partner: mission.partner || getValue(companyData[0], ['partner']) || props.partner, partner_custom: getValue(companyData[0], ['partner_custom']) || props.partner_custom, recovery: (mission.hasOwnProperty('recovery') && recovery === undefined) ? mission.recovery : recovery, options })} HT</span>
              </div>
              <div className={classes.travelSteps}>
                <div className={classes.travelLine}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className={classes.travelDetail}>
                  <div className={classes.travelDetailAddress}>
                    <span>{formData.origin_addresses}</span>
                  </div>
                  <div className={classes.travelDetailAddress}>
                    <span>{formData.destination_addresses}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button color="error" variant="destructive" onClick={() => setSummaryOpen(false)}>Annuler</Button>
              <Button variant="primary" onClick={handleChange}>{edit ? 'Éditer la mission' : 'Créer la mission'}</Button>
            </div>
          </Box>
        </Modal>
        <div className={classes.wrapper}>
          <form className={classes.form} name="geoSearch">

            {/* NEW */}
            <S.Wrapper>
              <S.Container>
                <S.InnerContainer>
                  <TitleSeparator title="Informations du trajet" />
                  <S.Flex>
                    {props.role === 'super-admin' && (!edit || props.duplicate) && <Formfields field={{ component: 'search', name: 'company_id', disabled: isDisabledEdit, label: 'Garage', data: companyData, handleSearch: handleCompanySearch, handleChange: handleCompanyData, handleSearchParams: { attribute_company: true } }} />}
                    <Formfields field={{ type: 'select', component: 'select', name: 'date_type', disabled: isDisabledEdit, label: 'Choix temporaire', dataLabel: 'name', dataValue: 'id', default: dateType, data: Object.values(window._DATA['date_type']).map((type) => { return { id: type.value, name: type.label }; }), handleChange: handleDateType }} />
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ type: 'date', component: 'datepicker', disabled: isDisabledEdit, name: 'vehicle_availability', label: 'Date de disponibilité du véhicule', defaultValue: mission.vehicle_availability ? mission.vehicle_availability.split('T')[0] : '', size: '100%', required: true, error: getValue(errors, ['vehicle_availability', 'message']) }} />
                    {[2, 3].includes(dateType) && <Formfields field={{ type: 'date', disabled: isDisabledEdit, component: 'datepicker', name: 'date', label: 'Date de livraison', defaultValue: mission.date ? mission.date.split('T')[0] : '', required: true, error: getValue(errors, ['date', 'message']) }} />}
                    {[2, 3].includes(dateType) && <Formfields field={{ type: "time", disabled: isDisabledEdit, component: 'text', name: 'time', label: 'Heure de livraison', default: mission.time, error: getValue(errors, ['time', 'message']) }} />}
                  </S.Flex>

                  {/* Adresses */}
                  <S.AddressContainer>
                    <S.AddressLine>
                      <span></span>
                      <span></span>
                      <span></span>
                    </S.AddressLine>
                    <form
                      id="addresses-form"
                      style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                    >
                      <GeoSearch name="origin" label="Adresse de départ" shrink={true} handleChange={handleChangeGeo} disabled={isDisabledEdit} default={edit && { address: mission.origin_address, city: mission.origin_city, cp: mission.origin_cp, geo: `${mission.origin_address} ${mission.origin_cp} ${mission.origin_city}`, }} />
                      <Formfields field={{ type: 'text', component: 'text', default: mission.origin_additional_address, disabled: isDisabledEdit, name: 'origin_additional_address', label: "Complément d'adresse", size: '100%', error: getValue(errors, ['origin_additional_address', 'message']) }} />
                      <GeoSearch name="destination" label="Adresse de livraison" shrink={true} handleChange={handleChangeGeo} disabled={isDisabledEdit} default={edit && { address: mission.destination_address, city: mission.destination_city, cp: mission.destination_cp, geo: `${mission.destination_address} ${mission.destination_cp} ${mission.destination_city}`, }} />
                      <Formfields field={{ type: 'text', component: 'text', default: mission.destination_additional_address, disabled: isDisabledEdit, name: 'destination_additional_address', label: "Complément d'adresse", size: '100%', error: getValue(errors, ['destination_additional_address', 'message']) }} />

                    </form>
                    {/* Garage address */}
                    <Formfields field={{ type: 'hidden', component: 'text', name: `origin_company_id`, hidden: true, value: getValue(localisation, ['origin', 'id']) || mission.origin_company_id }} />
                    <Formfields field={{ type: 'hidden', component: 'text', name: `origin_address`, hidden: true, value: getValue(localisation, ['origin', 'address']) || mission.origin_address }} />
                    <Formfields field={{ type: 'hidden', component: 'text', name: `origin_cp`, hidden: true, value: getValue(localisation, ['origin', 'cp']) || mission.origin_cp }} />
                    <Formfields field={{ type: 'hidden', component: 'text', name: `origin_city`, hidden: true, value: getValue(localisation, ['origin', 'city']) || mission.origin_city }} />
                    <Formfields field={{ type: 'hidden', component: 'text', name: `destination_company_id`, hidden: true, value: getValue(localisation, ['destination', 'id']) || mission.destination_company_id }} />
                    <Formfields field={{ type: 'hidden', component: 'text', name: `destination_address`, hidden: true, value: getValue(localisation, ['destination', 'address']) || mission.destination_address }} />
                    <Formfields field={{ type: 'hidden', component: 'text', name: `destination_cp`, hidden: true, value: getValue(localisation, ['destination', 'cp']) || mission.destination_cp }} />
                    <Formfields field={{ type: 'hidden', component: 'text', name: `destination_city`, hidden: true, value: getValue(localisation, ['destination', 'city']) || mission.destination_city }} />
                  </S.AddressContainer>

                  <TitleSeparator title="Informations véhicule" />

                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', name: 'vehicle_brand', label: 'Marque du véhicule', default: mission.vehicle_brand, required: true, error: getValue(errors, ['vehicle_brand', 'message']) }} />
                    <Formfields field={{ type: 'text', component: 'text', name: 'vehicle_model', label: 'Modèle du véhicule', default: mission.vehicle_model, required: true, error: getValue(errors, ['vehicle_model', 'message']) }} />
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ type: 'select', component: 'select', name: 'vehicle_type', label: 'Type de véhicule', dataLabel: 'name', dataValue: 'id', default: 1, data: Object.values(window._DATA['vehicle_type']).map((type) => { return { id: type.value, name: type.label }; }), handleChange: handleVehicleType, }} />
                    {props.role === 'super-admin' && (!edit || (edit && !mission.driver_id) || props.duplicate) && <Formfields field={{ component: 'search', name: 'driver_id', label: 'Définir un convoyeur', disabled: isDisabledEdit, data: users, default: mission.driver_id && { label: `${mission.driver_name} ${mission.driver_firstname} (${mission.driver_email})`, id: mission.driver_id }, handleSearch: handleUserSearch, handleChange: (e) => setSelectedDriver(getValue(e, ['value', 'id'])) }} />}
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', name: 'car_registration', label: 'Immatriculation', default: mission.car_registration, required: false, error: getValue(errors, ['car_registration', 'message']) }} />
                    <Formfields field={{ type: 'text', component: 'text', name: 'car_grey_card', label: 'Numéro de châssis', default: mission.car_grey_card, required: false, error: getValue(errors, ['car_grey_card', 'message']) }} />
                  </S.Flex>
                  <Formfields field={{ component: 'text', name: 'detail', label: 'Informations complémentaires', default: mission.detail, error: getValue(errors, ['detail', 'message']) }} />


                  {checkGeo && <div>
                    <div>
                      <h2>Récapitulatif</h2>
                      <span>{`${dateType !== 2 ? window._DATA.date_type[dateType].label : ''} ${formatDate({ time: formData.date, display: 'date' })}`}</span>
                    </div>
                    <S.Flex>
                      <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="" className={classes.travelIcon}>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M13 12H18"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M12 5V11"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"></path>
                        </svg>
                        <span>{formatDistanceTime({ duration: formData.duration })}</span>
                        <Formfields field={{ type: 'hidden', component: 'text', name: 'duration', hidden: true, value: formData.duration }} />
                      </div>
                      <div>
                        {vehicleIcon}
                        <span>{formatDistanceTime({ distance: formData.distance })}</span>
                        <Formfields field={{ type: 'hidden', component: 'text', name: 'distance', hidden: true, value: formData.distance }} />
                      </div>
                      <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-reactroot="" className={classes.travelIcon}>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M22 5H2V19H22V5Z"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M18 5C18 7.21 19.79 9 22 9V5H18Z"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M18 19C18 16.79 19.79 15 22 15V19H18Z"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M6 5C6 7.21 4.21 9 2 9V5H6Z"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" fill="none" d="M6 19C6 16.79 4.21 15 2 15V19H6Z"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M12 16V14.99"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M12 9.01V8"></path>
                          <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" stroke="#221b38" d="M14 10.2701C13.83 9.65006 13.28 9.17006 12.58 9.04006C11.88 8.91006 11.15 9.15006 10.74 9.64006C10.69 9.70006 10.23 10.2601 10.45 10.8601C10.59 11.2401 10.92 11.4501 11.38 11.7301C11.88 12.0401 12.02 11.9901 12.78 12.3801C13.55 12.7801 13.68 12.9801 13.74 13.0801C13.96 13.5001 13.81 13.9201 13.78 14.0001C13.57 14.5401 12.99 14.7601 12.78 14.8501C12.69 14.8901 11.85 15.1901 11.02 14.8101C10.56 14.6001 10.32 14.2801 10.24 14.1801C10.11 13.9901 10.04 13.8201 10 13.6901"></path>
                        </svg>
                        <span>
                          {getPrice({ vehicle_type: vehicleType, formData, partner: mission.partner || getValue(companyData[0], ['partner']) || props.partner, partner_custom: getValue(companyData[0], ['partner_custom']) || props.partner_custom, recovery: (mission.hasOwnProperty('recovery') && recovery === undefined) ? mission.recovery : recovery, options })} HT
                        </span>
                      </div>
                    </S.Flex>
                    <div className={classes.cancelMissionContainer}>
                      <p>Inclus : frais de convoyage, de péage, de carburant, d’approche du chauffeur et de retour du convoyeur.</p>
                      <div className={classes.cancelMissionContent}>
                        <span>{`Annulation jour J < 24h : 100% facturé`}</span>
                        <span>{`Annulation J < 48h : 50% facturé`}</span>
                        <span>{`Annulation > 48h Aucun frais`}</span>
                      </div>
                    </div>
                  </div>}
                </S.InnerContainer>
              </S.Container>

              <S.Container>
                <S.InnerContainer>
                  <TitleSeparator title="Options supplémentaires" />
                  <S.Flex>
                    <Formfields field={{ component: 'switch', size: '12px', name: 'insurance', label: `Assurance (${getValue(options, [1, 'insurance', 'value']) || 20} HT)`, default: mission.insurance, onChange: disableItineraire, disabled: isDisabledEdit || insuranceInput.disabled, value: insuranceInput.checked, error: getValue(errors, ['insurance', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'special_plate', label: `w-garage (${getValue(options, [1, 'special_plate', 'value']) || 20}€ HT)`, default: mission.special_plate, onChange: disableItineraire, error: getValue(errors, ['special_plate', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'cleaning', label: `Nettoyage extérieur (${getValue(options, [1, 'cleaning', 'value']) || 10}€ HT)`, default: mission.cleaning, onChange: disableItineraire, error: getValue(errors, ['cleaning', 'message']) }} />
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'easy_to_use', label: `Mise en main simple (${getValue(options, [1, 'easy_to_use', 'value']) || 10}€ HT)`, default: mission.easy_to_use, onChange: disableItineraire, error: getValue(errors, ['easy_to_use', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'electric', label: 'Véhicule électrique', onChange: setElectricChecked, default: mission.electric, error: getValue(errors, ['electric', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'refueling', label: 'Remise à niveau du carburant', default: mission.hasOwnProperty('refueling') ? mission.refueling : 1, error: getValue(errors, ['refueling', 'message']) }} />
                  </S.Flex>
                  {electricChecked ? <p style={{ fontSize: '14px' }}>Attention, un supplément de 50€ ht par recharge est à prendre en compte pour un véhicule électrique</p> : ''}
                  {options[2] && options[2].length && <>
                    <TitleSeparator title="Options personnalisées" />
                    <S.Flex>
                      {options[2].map((option) => {
                        return <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: `option_custom_${option.id}`, label: `${option.name} (${option.value}€ HT)`, onChange: disableItineraire, default: mission.options_custom && mission.options_custom.split(',').includes(String(option.id)) ? 1 : 0 }} />
                      })}
                    </S.Flex>
                  </>}

                  <TitleSeparator title="Coordonnées du contact de départ" />
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', default: mission.starting_contact_firstname, name: 'starting_contact_firstname', label: mission.starting_contact_name ? 'Prénom' : 'Prénom / Nom / Garage', required: true, size: '100%', handleChange: (e) => { if (!contactName.recovery) setContactName({ value: e, recovery: false }) }, error: getValue(errors, ['starting_contact_firstname', 'message']) }} />
                    {mission.starting_contact_name && <Formfields field={{ type: 'text', component: 'text', default: mission.starting_contact_name, name: 'starting_contact_name', label: 'Nom', required: true, size: '100%', error: getValue(errors, ['starting_contact_name', 'message']) }} />}
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', default: mission.starting_contact_phone, name: 'starting_contact_phone', label: 'Numéro de téléphone', required: true, size: '100%', handleChange: (e) => { if (!contactPhone.recovery) setContactPhone({ value: e, recovery: false }) }, error: getValue(errors, ['starting_contact_phone', 'message']) }} />
                    <Formfields field={{ type: 'text', component: 'text', default: mission.starting_contact_email, name: 'starting_contact_email', label: 'Email', required: false, size: '100%', handleChange: (e) => { if (!contactEmail.recovery) setContactEmail({ value: e, recovery: false }) }, error: getValue(errors, ['starting_contact_email', 'message']) }} />
                  </S.Flex>
                  <Formfields field={{ type: 'text', component: 'text', default: mission.starting_contact_company, name: 'starting_contact_company', label: 'Société', required: false, size: '100%', handleChange: (e) => { if (!contactCompany.recovery) setContactCompany({ value: e, recovery: false }) }, error: getValue(errors, ['starting_contact_company', 'message']) }} />

                  <TitleSeparator title="Coordonnées du contact d'arrivée" />
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', default: mission.incoming_contact_firstname, name: 'incoming_contact_firstname', label: mission.incoming_contact_name ? 'Prénom' : 'Prénom / Nom / Garage', required: true, size: '100%', error: getValue(errors, ['incoming_contact_firstname', 'message']) }} />
                    {mission.incoming_contact_name && <Formfields field={{ type: 'text', component: 'text', default: mission.incoming_contact_name, name: 'incoming_contact_name', label: 'Nom', required: true, size: '100%', error: getValue(errors, ['incoming_contact_name', 'message']) }} />}
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', default: mission.incoming_contact_phone, name: 'incoming_contact_phone', label: 'Numéro de téléphone', required: true, size: '100%', error: getValue(errors, ['incoming_contact_phone', 'message']) }} />
                    <Formfields field={{ type: 'text', component: 'text', default: mission.incoming_contact_email, name: 'incoming_contact_email', label: 'Email', required: false, size: '100%', error: getValue(errors, ['incoming_contact_email', 'message']) }} />
                  </S.Flex>
                  <Formfields field={{ type: 'text', component: 'text', default: mission.incoming_contact_company, name: 'incoming_contact_company', label: 'Société', required: false, size: '100%', error: getValue(errors, ['incoming_contact_company', 'message']) }} />
                </S.InnerContainer>

                {checkGeo && <div>
                  <iframe
                    width="450"
                    height="300"
                    frameBorder="0"
                    style={{ border: 0 }}
                    referrerPolicy="no-referrer-when-downgrade"
                    className={classes.travelMap}
                    src={`https://www.google.com/maps/embed/v1/${checkGeo ? 'directions' : 'search'
                      }?key=AIzaSyAJfF_PxWiAglDxqlioI71wsd0gjZyx0RY&${checkGeo
                        ? `&origin=${formData.origin_addresses}&destination=${formData.destination_addresses}`
                        : 'q=""'
                      }`}
                    allowFullScreen
                  ></iframe>
                </div>}
              </S.Container>
            </S.Wrapper>
            {((mission.hasOwnProperty('recovery') && mission.recovery && recovery === undefined) || Boolean(recovery)) && <S.Wrapper>
              <S.Container>
                <S.InnerContainer>
                  {Boolean(Object.keys(recoveryDestination).length) && <>
                    <TitleSeparator title="Informations du trajet" />
                    <GeoSearch name="recovery_destination" label="Adresse de livraison" disabled={isDisabledEdit} shrink={true} default={recoveryDestination} />
                    <Formfields field={{ type: 'text', disabled: isDisabledEdit, component: 'text', default: getValue(mission, ['recovery_mission', 'destination_additional_address']), name: 'recovery_destination_additional_address', label: "Complément d'adresse", size: '100%', error: getValue(errors, ['recovery_destination_additional_address', 'message']) }} />
                  </>}
                  <S.Flex>
                    <Formfields field={{ type: 'select', component: 'select', disabled: isDisabledEdit, name: 'recovery_date_type', label: 'Choix temporaire', dataLabel: 'name', dataValue: 'id', default: recoveryDateType, data: Object.values(window._DATA['date_type']).map((type) => { return { id: type.value, name: type.label }; }), handleChange: (e) => { setRecoveryDateType(e) } }} />
                  </S.Flex>
                  <S.Flex>
                    {[2, 3].includes(recoveryDateType) && <Formfields field={{ type: 'date', disabled: isDisabledEdit, component: 'datepicker', name: 'recovery_date', label: 'Date de livraison', defaultValue: getValue(mission, ['recovery_mission', 'date']) ? getValue(mission, ['recovery_mission', 'date']).split('T')[0] : '', required: true, error: getValue(errors, ['recovery_date', 'message']) }} />}
                    {[2, 3].includes(recoveryDateType) && <Formfields field={{ type: "time", disabled: isDisabledEdit, component: 'text', name: 'recovery_time', label: 'Heure de livraison', default: getValue(mission, ['recovery_mission', 'time']), error: getValue(errors, ['recovery_time', 'message']) }} />}
                  </S.Flex>
                  <TitleSeparator title="Informations véhicule de reprise" />
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', name: 'recovery_vehicle_brand', label: 'Marque du véhicule', default: getValue(mission, ['recovery_mission', 'vehicle_brand']), required: true, error: getValue(errors, ['recovery_vehicle_brand', 'message']) }} />
                    <Formfields field={{ type: 'text', component: 'text', name: 'recovery_vehicle_model', label: 'Modèle du véhicule', default: getValue(mission, ['recovery_mission', 'vehicle_model']), required: true, error: getValue(errors, ['recovery_vehicle_model', 'message']) }} />
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', name: 'recovery_car_registration', label: 'Immatriculation', default: getValue(mission, ['recovery_mission', 'car_registration']), required: false, error: getValue(errors, ['recovery_car_registration', 'message']) }} />
                    <Formfields field={{ type: 'text', component: 'text', name: 'recovery_car_grey_card', label: 'Numéro de châssis', default: getValue(mission, ['recovery_mission', 'car_grey_card']), required: false, error: getValue(errors, ['recovery_car_grey_card', 'message']) }} />
                  </S.Flex>
                  <Formfields field={{ component: 'text', name: 'recovery_detail', label: 'Informations complémentaires', default: getValue(mission, ['recovery_mission', 'detail']), error: getValue(errors, ['recovery_detail', 'message']) }} />
                </S.InnerContainer>
              </S.Container>
              <S.Container>
                <S.InnerContainer>
                  <TitleSeparator title="Options supplémentaires" />
                  <S.Flex>
                    <Formfields field={{ component: 'switch', size: '12px', name: 'recovery_insurance', label: `Assurance (${getValue(options, [1, 'insurance', 'value']) || 20} HT)`, default: getValue(mission, ['recovery_mission', 'insurance']), onChange: disableItineraire, disabled: isDisabledEdit || recoveryInsuranceInput.disabled, value: recoveryInsuranceInput.checked, error: getValue(errors, ['recovery_insurance', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'recovery_special_plate', label: `w-garage (${getValue(options, [1, 'special_plate', 'value']) || 20}€ HT)`, default: getValue(mission, ['recovery_mission', 'special_plate']), onChange: disableItineraire, error: getValue(errors, ['recovery_special_plate', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'recovery_cleaning', label: `Nettoyage extérieur (${getValue(options, [1, 'cleaning', 'value']) || 10}€ HT)`, default: getValue(mission, ['recovery_mission', 'cleaning']), onChange: disableItineraire, error: getValue(errors, ['recovery_cleaning', 'message']) }} />
                  </S.Flex>
                  <S.Flex>
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'recovery_easy_to_use', label: `Mise en main simple (${getValue(options, [1, 'easy_to_use', 'value']) || 10}€ HT)`, default: getValue(mission, ['recovery_mission', 'easy_to_use']), onChange: disableItineraire, error: getValue(errors, ['recovery_easy_to_use', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'recovery_electric', label: 'Véhicule électrique', onChange: setElectricRecoveryChecked, default: getValue(mission, ['recovery_mission', 'electric']), error: getValue(errors, ['recovery_electric', 'message']) }} />
                    <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: 'recovery_refueling', label: 'Remise à niveau du carburant', default: getValue(mission, ['recovery_mission', 'refueling']), error: getValue(errors, ['refueling', 'message']) }} />
                  </S.Flex>
                  {electricRecoveryChecked ? <p style={{ fontSize: '14px' }}>Attention, un supplément de 50€ ht par recharge est à prendre en compte pour un véhicule électrique</p> : ''}
                  {options[2] && options[2].length && <>
                    <TitleSeparator title="Options personnalisées" />
                    <S.Flex>
                      {options[2].map((option) => {
                        return <Formfields field={{ component: 'switch', size: '12px', disabled: isDisabledEdit, name: `recovery_option_custom_${option.id}`, label: `${option.name} (${option.value}€ HT)`, onChange: disableItineraire, default: getValue(mission, ['recovery_mission', 'options_custom']) && mission.recovery_mission.options_custom.split(',').includes(String(option.id)) ? 1 : 0 }} />
                      })}
                    </S.Flex>
                  </>}
                  <TitleSeparator title="Coordonnées du contact d'arrivée" />
                  <S.Flex>
                    <Formfields field={{ type: 'text', component: 'text', default: getValue(mission, ['recovery_mission', 'starting_contact_firstname']) || mission.starting_contact_firstname, name: 'recovery_starting_contact_firstname', label: getValue(mission, ['recovery_mission', 'recovery_starting_contact_name']) ? 'Prénom' : 'Prénom / Nom / Garage', required: true, size: '100%', value: contactName.value, handleChange: (e) => { setContactName({ value: e, recovery: true }) }, error: getValue(errors, ['recovery_starting_contact_firstname', 'message']) }} />
                    {getValue(mission, ['recovery_mission', 'starting_contact_name']) && <Formfields field={{ type: 'text', component: 'text', default: getValue(mission, ['recovery_mission', 'starting_contact_name']) || mission.starting_contact_name, name: 'recovery_starting_contact_name', label: 'Nom', required: true, size: '100%', error: getValue(errors, ['recovery_incoming_contact_name', 'message']) }} />}
                  </S.Flex>
                  <S.Flex>
                    {/* handleChange: (e) => { if (!contactPhone.recovery) setContactPhone({ value: e, recovery: false }) } */}
                    <Formfields field={{ type: 'text', component: 'text', default: getValue(mission, ['recovery_mission', 'starting_contact_phone']) || mission.starting_contact_phone, name: 'recovery_starting_contact_phone', value: contactPhone.value, handleChange: (e) => { setContactPhone({ value: e, recovery: true }) }, label: 'Numéro de téléphone', required: true, size: '100%', error: getValue(errors, ['recovery_starting_contact_phone', 'message']) }} />
                    <Formfields field={{ type: 'text', component: 'text', default: getValue(mission, ['recovery_mission', 'starting_contact_email']) || mission.starting_contact_email, name: 'recovery_starting_contact_email', value: contactEmail.value, handleChange: (e) => { setContactEmail({ value: e, recovery: true }) }, label: 'Email', required: false, size: '100%', error: getValue(errors, ['recovery_starting_contact_email', 'message']) }} />
                  </S.Flex>
                  <Formfields field={{ type: 'text', component: 'text', default: getValue(mission, ['recovery_mission', 'starting_contact_company']) || mission.starting_contact_company, name: 'recovery_starting_contact_company', value: contactCompany.value, handleChange: (e) => { setContactCompany({ value: e, recovery: true }) }, label: 'Société', required: false, size: '100%', error: getValue(errors, ['recovery_starting_contact_company', 'message']) }} />
                </S.InnerContainer>
              </S.Container>
            </S.Wrapper>}
            {/* END NEW */}
            {(!edit || props.duplicate) && <S.DropzoneContainer>
              <Dropzone multiple={true} onFilesChange={handleFilesChange} />
            </S.DropzoneContainer>}
            <S.ButtonContainer>
              <Button variant="secondary" onClick={() => setRecovery(!isRecovery)}>{isRecovery ? 'Simple' : 'Reprise'}</Button>
              {!checkGeo && <Button onClick={handleGeoSearch}>Itinéraire</Button>}
              {checkGeo && props.role === 'super-admin' && <Button onClick={() => handleModal()}>Valider la mission</Button>}
              {checkGeo && ((props.role !== 'super-admin' && !edit) || props.role === 'garage') && <Button className={classes.formSubmit} onClick={() => setSummaryOpen(true)}>Confirmer</Button>}
            </S.ButtonContainer>

            {edit && !props.duplicate && <MissionFiles mission_id={mission.uuid} />}
          </form>
        </div>
        <Modal
          open={validationModalOpen}
          onClose={() => setValidationModalOpen(false)}
        >
          <Box className={classes.validationModal}>
            <h2>Validation de la mission</h2>
            <form id='validation_form' className={classes.validationForm}>
              <Formfields field={{ component: 'text', name: 'distance', label: 'Distance (km)', default: Math.round(formData.distance), required: true, error: getValue(errors, ['distance', 'message']) }} />
              <div className={classes.modalInput}>
                <Formfields field={{ component: 'text', name: 'price', label: 'Prix (€)', default: priceFinal, required: true, error: getValue(errors, ['price', 'message']) }} />
                <Formfields field={{ component: 'text', name: 'price_driver', label: 'Prix convoyeur (€)', default: (Number.isInteger(priceFinal - (priceFinal * 0.33)) ? (priceFinal - (priceFinal * 0.33)) : (priceFinal - (priceFinal * 0.33)).toFixed(2)), required: true, error: getValue(errors, ['price_driver', 'message']) }} />
              </div>
              {selectedDriver && <div className={classes.modalInput}>
                <Formfields field={{ type: 'text', component: 'text', name: 'weproov_code', label: 'Code weproov', required: true, error: getValue(errors, ['weproov_code', 'message']) }} />
                {((mission.hasOwnProperty('recovery') && mission.recovery && recovery === undefined) || recovery) && <Formfields field={{ type: 'text', component: 'text', name: 'recovery_weproov_code', label: 'Code weproov reprise', required: true, error: getValue(errors, ['recovery_weproov_code', 'message']) }} />}
              </div>}
            </form>
            <Button variant="primary" onClick={() => { handleChange() }}>Valider la mission</Button>
          </Box>
        </Modal>
      </>
    )
  );
};

export default CreateMission;

const S = {};

S.Wrapper = styled.div`
  display: flex;
  background:white;
  box-shadow: 0 20px 27px 0 rgb(0 ,0, 0, 5%);
  border-radius: 10px;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`

S.Container = styled.div`
  width:50%;
  display: flex;
  flex-direction:column;
  gap:20px;
  padding:20px 0 0 20px;

  @media (max-width: 1300px) {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`

S.InnerContainer = styled.div`
  padding:0 20px 20px 0;
  display: flex;
  flex-direction:column;
  gap:20px;

  @media (max-width: 1300px) {
    padding: 0px;
    box-sizing: border-box;
  }
`

S.Flex = styled.div`
  display: flex;
  gap:20px;
  &>div{
    flex:1
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`

S.AddressContainer = styled.div`
  position: relative;
  padding-left: 40px;
`
S.AddressLine = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:4px;
  position:absolute;
  left:0;
  top:5px;
  height:calc(100% - 15px);
  span:nth-child(2){
    height:100%;
    width:2px;
    border-left: 2px dotted var(--primary-color)
  }
  span:not(:nth-child(2)){
    width:22px;
    min-height:22px;
    background:#14483C;
    border-radius: 100%;
    border:2px solid var(--primary-color);
  }
  span:nth-child(1){
    background:white;
  }
  span:nth-child(3){
    background-color: var(--primary-color);
    background-image: linear-gradient(45deg, #FFF 25%, transparent 25%, transparent 75%, #FFF 75%), linear-gradient(45deg, #FFF 25%, transparent 25%, transparent 75%, #FFF 75%);
    background-size: 8px 8px;
    background-position: -2px -2px, 50px 50px;
    border-color: var(--primary-color);
  }
`

S.ButtonContainer = styled.div`
  display:flex;
  gap:10px;
  justify-content: flex-end;
`
S.DropzoneContainer = styled.div`
  background:white;
  border-radius:10px;
  padding:20px;
`